// Generated by Framer (241a4b4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["S8VSfsm29"];

const variantClassNames = {S8VSfsm29: "framer-v-3e641w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, l1CU0Kf15: title ?? props.l1CU0Kf15 ?? "Blog News"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, l1CU0Kf15, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "S8VSfsm29", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-VRIem", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-3e641w", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"S8VSfsm29"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "RlM7U3dpdHplci1ib2xk", "--framer-font-family": "\"Switzer\", sans-serif", "--framer-font-size": "72px", "--framer-font-weight": "700", "--framer-letter-spacing": "-2px", "--framer-line-height": "58px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-1of0zx5)"}}><motion.span data-text-fill={"true"} style={{backgroundImage: "linear-gradient(0deg, rgb(46, 80, 97) 0%, rgb(108, 117, 181) 100%)"}}>Blog News</motion.span></motion.h2></React.Fragment>} className={"framer-fdsbth"} data-framer-name={"Heading"} fonts={["FS;Switzer-bold"]} layoutDependency={layoutDependency} layoutId={"xyM_UM6BB"} style={{"--extracted-1of0zx5": "var(--token-21a286de-1aa4-46f8-b6a2-c87cdaf59e6b, rgb(93, 33, 209))", "--framer-paragraph-spacing": "0px"}} text={l1CU0Kf15} verticalAlignment={"center"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-VRIem [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-VRIem .framer-1g6imw5 { display: block; }", ".framer-VRIem .framer-3e641w { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1000px; }", ".framer-VRIem .framer-fdsbth { flex: none; height: auto; overflow: hidden; position: relative; white-space: pre-wrap; width: 1000px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-VRIem .framer-3e641w { gap: 0px; } .framer-VRIem .framer-3e641w > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-VRIem .framer-3e641w > :first-child { margin-left: 0px; } .framer-VRIem .framer-3e641w > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 58
 * @framerIntrinsicWidth 1000
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"l1CU0Kf15":"title"}
 */
const FramerDbqPq6A96: React.ComponentType<Props> = withCSS(Component, css, "framer-VRIem") as typeof Component;
export default FramerDbqPq6A96;

FramerDbqPq6A96.displayName = "Big-Heading-Center";

FramerDbqPq6A96.defaultProps = {height: 58, width: 1000};

addPropertyControls(FramerDbqPq6A96, {l1CU0Kf15: {defaultValue: "Blog News", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerDbqPq6A96, [{family: "Switzer", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/DbqPq6A96:default", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/HBNTRIISA5MEXGL5WPYI7CV2HIWTDV3Q/YDPDINVT673XLXNSTMLG4JNCZZMVVNPN/Y7SCNZJOT2MW5ADSGOFLDGH4TNL4JCQY.woff2"}, style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/HBNTRIISA5MEXGL5WPYI7CV2HIWTDV3Q/YDPDINVT673XLXNSTMLG4JNCZZMVVNPN/Y7SCNZJOT2MW5ADSGOFLDGH4TNL4JCQY.woff2", weight: "700"}])